@import 'theme/global/site-fonts';
@import 'modules/normalize';
@import 'modules/reset';
@import 'modules/pcs';
@import "theme/global/icomoon-base";
@import "theme/global/icomoon";

// Magento Vendor
@import 'vendor/magento-ui/lib';

// Reset and reboot
@import 'base/media-queries';
@import 'base/main';
@import 'base/links';
@import 'base/lists';
@import 'base/sectioning';
@import 'base/grouping';
@import 'base/headings';
@import 'base/paragraphs';
@import 'base/quotes';
@import 'base/preformatted';
@import 'base/media';
@import 'base/tables';
@import 'base/inline';
@import 'base/forms';
@import 'base/buttons';
@import 'base/svg';
@import 'base/tooltip';
@import 'base/flickity-default';

// Page Builder
@import 'page-builder/module';

@import 'theme/global/site-top';
@import 'theme/global/site-header';
@import 'theme/global/_site-usps';
@import 'theme/global/site-controls';
@import 'theme/global/delivery-timer';
@import 'theme/global/minicart';
@import 'theme/global/minicart-button';
@import 'theme/global/site-search';
@import 'theme/global/site-nav';
@import 'theme/global/sub-nav';
@import 'theme/global/site-layout';
@import 'theme/global/social-links';
@import 'theme/global/site-footer';
@import 'theme/global/footer-newsletter';
@import 'theme/global/site-legal';
@import 'theme/global/page-messages';
@import 'theme/global/contact-popup';
@import 'theme/global/catalogsearch-result-index';
@import 'theme/global/captcha';

@import 'theme/category/product-list';
@import 'theme/category/toolbar';
@import 'theme/category/filter';
@import 'theme/category/filter-current';
@import 'theme/category/toolbar';
@import 'theme/category/filter';
@import 'theme/category/filter-current';
@import 'theme/category/_product-list-types';

@import 'theme/product/product-page-layout';
@import 'theme/product/product-info-detailed';
@import 'theme/product/add-multiple';
@import 'theme/product/product-media';
@import 'theme/product/product-related';
@import 'theme/product/product-sticker';
@import 'theme/product/product-offer';
@import 'theme/product/embellishments';
@import 'theme/product/recently-viewed';
@import 'theme/product/custom-swatches';
@import 'theme/product/membership';

@import 'theme/account/customer-account-layout';
@import 'theme/account/customer-account-address';
@import 'theme/account/customer-account-nav';
@import 'theme/account/customer-account-login';
@import 'theme/account/customer-account-wishlist';
@import 'theme/account/customer-account-information';
@import 'theme/account/customer-account-create';
@import 'theme/account/_customer-account-order-views';
@import 'theme/account/_customer-order-tables';
@import 'theme/account/_customer-account-membership';

//Basket
@import 'theme/checkout/basket';
@import 'theme/checkout/dpd';
@import 'theme/checkout/cart-summary';

@import 'theme/checkout/modals';
@import 'theme/checkout/checkout-layout';
@import 'theme/checkout/checkout-shipping-address';
@import 'theme/checkout/checkout-shipping-method';
@import 'theme/checkout/checkout-payment-method';
@import 'theme/checkout/checkout-proclub';
@import 'theme/checkout/iosc-summary';
@import 'theme/checkout/loading-mask';
@import 'theme/checkout/progress-bar';
@import 'theme/checkout/checkout-agreement';
@import 'theme/checkout/braintree';
@import 'theme/checkout/free-gift';

@import 'theme/cookie-notice';
@import 'theme/prices';
@import 'theme/sidebar-main';
@import 'theme/breadcrumbs';
@import 'theme/added-to-basket';
@import 'theme/carousel';
@import 'theme/brands';
@import 'theme/tile';
@import 'theme/category-page';
@import 'theme/mobile-navigation';
@import 'theme/category-landing-tile';
@import 'theme/product-add';
@import 'theme/brand-listing';
@import 'theme/new-in-carousel';
@import 'theme/proposition-banner';
@import 'theme/cms-page-view';
@import 'theme/marketing-preferences-modal';
@import 'theme/klarna-landing';


@import 'scss-vendor/magnific-popup.scss';
@import 'scss-vendor/magnific-popup-overides';
@import 'scss-vendor/gallery-overides';
@import 'scss-vendor/fotorama-override';

@import 'catalogue/order-catalogue';
@import 'theme/quickorder-index-index';
@import "theme/home/home";

@import 'vendor/amasty/_gdpr.scss';
@import "theme/_blog-extend.scss";
@import 'vendor/amasty/_promo.scss';

//misc

.hidden {
    display: none !important;
}

.desk-only {
    @include media-query(max, $lap-start) {
        display: none !important;
    }
}
.mob-only {
    @include media-query(min, $lap-start) {
        display: none !important;
    }
}
