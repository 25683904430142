.add-multiple {
    display: block;
    text-align: center;
    order: 3;

    .fieldset__option-divider {
        display: block;
        padding: 0 $base-spacing;
        font-weight: 400;
        @include font-size($micro-font-size);

        @include media-query (min, $desk-start) {
            margin-bottom: 0;
            @include font-size($base-font-size);
        }
    }

    .button {
        display: block;
        width: 100%;
        color: $white;

        &:hover {
            color: $white;
        }
    }

    @include media-query(min, $desk-start) {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        order: 2;
        margin-bottom: $base-spacing;
    }
}

//POPUP
.add-multiple-products-popup {
    position: relative;
    width: 100%;
    max-width: 570px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: $double-spacing 0 0;
    background-color: $white;

    @include media-query(min, $lap-start) {
        padding: $double-spacing 0 0;
    }

    .mfp-close {
        @include hover {
            background-color: transparent;
        }
    }
}

.add-multiple-products-form-container {
    outline: 0;
}

.add-multiple-products-form-title {
    display: none;
}

.add-multiple-products-form {
    position: relative;
}

.add-multiple-messages {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.96);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
}

.add-multiple-messages--active {
    opacity: 1;
    visibility: visible;
}

.add-multiple-messages__content {
    color: black;
    width: 100%;
    text-align: center;

    button {
        width: 100%;
        max-width: 250px;

    }
}

.add-multiple-status {
    display: block;
    position: relative;

    &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.add-multiple-status--success {
    &:before {
        background: url('../img/icon_tick_green.svg') 50% no-repeat;
    }
}

.add-multiple-status--error {
    &:before {
        background: url('../img/icon_cross_red.svg') 50% no-repeat;
    }
}

.add-multiple-products-overflow-wrapper {
    max-height: 50vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid $silver-lt;

    width: 100%;
    padding: $half-spacing;

    @include media-query(min, $palm-start) {
        flex-wrap: nowrap;
    }

    @include hover {
        background-color: $mercury;
    }

    &:focus-within {
        background-color: $silver-lt;
    }

    &.out-of-stock {
        order: 9999;

        .price,
        .label {
            opacity: 0.5;
        }
    }

    .label {
        display: block;
        @include font-size($small-font-size);
        margin-bottom: 0;
    }

    .price {
        display: block;
        @include font-size($small-font-size);
    }

    .field.qty {
        margin: 0;
        margin-left: auto;
        padding-left: $half-spacing;
        flex-shrink: 0;
        flex-grow: 0;

        .product-add__button {
            background: $white;
            @include hover {
                background: $white;
            }
        }
    }

    .stock-status {
        @include font-size($micro-font-size);

        @include media-query (min, $palm-start) {
            @include font-size($small-font-size);
        }

        .svg-icon {
            position: relative;
            top: -2px;
            color: $red;
            width: 0.8em;
            height: 0.8em;
        }
    }

    .custom-swatches__hex {
        display: none;

        .showSwatches & {
            display: block;
        }
    }
}

.fieldset__option-divider {
    color: $charcoal;
    font-weight: 700;
    margin-bottom: $base-spacing;

    @include media-query(min, $desk-start) {
        padding: 0 $base-spacing;
    }
}

.add-multiple-products-form-button__bg {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: $half-spacing;
    text-align: center;
    border-top: 1px solid $silver-lt;

    @include media-query (min, $desk-start) {
        padding: $base-spacing;
    }

    .action.primary.tocart {
        width: 100%;

        &[disabled] {
            opacity: 0.6;
            cursor: not-allowed;
        }

        @include media-query(min, $palm-start) {
            width: 250px;
            margin: 0 auto;
        }
    }
}

.add-multiple-products-form-button__reset {
    display: block;
    background: none;
    border: none;
    text-decoration: underline;
    width: auto;
    margin: $half-spacing auto 0;
    padding: 0;

    @include hover {
        background: none;
        border: none;
        text-decoration: underline;
    }
}

.catalog-product-view.restricted .add-multiple {
    display: none;
}
