.site-footer {
    background: $footer-background-color;
    margin-bottom: 0;
    padding-bottom: $double-spacing;
    color: $footer-font-color;
    border-top: $border;
    margin-top: $double-spacing;
    clear: both;
    z-index: 0;
    position: relative;
    .label,
    h4,
    .site-footer__info-content-number,
    .social-links__link,
    .site-footer__info-content a {
        color: $footer-font-color;
    }
    .site-footer__info-content h6 {
        padding-top: 0;
    }
    .site-legal__column {
        margin-bottom: 0;
    }
}
.site-footer__info {
    position: relative;
}
.site-footer__info-content {
    a {
        font-size: 16px;
    }
    ul {
        list-style: none;
    }
}
.site-footer__title {
    margin: 0;
    padding-bottom: 20px;
    position: relative;
}
.site-legal__copyright {
    border-top: $border;
    span {
        border-top: none;
    }
}
.site_footer__trade-container,
.site-footer__secret {
    margin-bottom: 20px;
    text-align: left;
}
a.site-footer__info-content-number {
    color: $footer-font-color;
    font-size: 20px;
    font-weight: $font-weight__bold;
    margin-bottom: $half-spacing;
}
.site-footer__trade,
.site-footer__secret {
    @include media-query(min, $lap-start) {
        width: 400px;
    }
    border: $border;
    background-color: $white;
    color: $trade-font-color;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        text-decoration: initial;
        color: $trade-font-color;
    }
}

.site-footer__secret-left-title {
    font-size: 18px;
    font-weight: bold;
}

.site-footer__secret-left-subtitle {
    font-size: 14px;
    text-decoration: underline;
}

.site-footer__trade-subtext {
    font-size: 11px;
    line-height: initial;
}

.site-footer__trade-right-image,
.site-footer__secret-right-image {
    object-fit: contain;
    height: 50px;
    width: 120px;
    @include media-query(min, $lap-start) {
        width: 150px;
    }
}

@include media-query(min, $desk-start) {
    div.site-footer__info {
        grid-template-columns: 3fr 1fr;
    }
}

@include media-query(min, $lap-start) {
    .site-footer__info {
        grid-template-columns: 1fr;
        display: grid;
        grid-gap: $base-spacing;
        [data-content-type="row"] {
            width: 100%;
        }
    }
    .site-footer__title:before {
        display: none;
    }
    @include media-query(max, $desk-start) {
        .site-footer__info-content--contact {
            grid-column: span 2;
            display: grid;
            grid-gap: $base-spacing;
            grid-template-columns: 1fr 1fr;
        }
        a.site-footer__info-content-number {
            font-size: 30px;
        }
    }
}

@include media-query(max, $lap-start) {
    footer.site-footer {
        padding-bottom: 10px;
    }
    div.footer-newsletter__control {
        flex-direction: row;
    }
    ul.footer-newsletter__social {
        flex: unset;
        margin-top: 10px;
        .social-links__item {
            margin-right: 20px;
        }
    }
    .site-footer__info {
        .pagebuilder-column-group {
            grid-gap: 0;
        }
        .site-footer__info-content,
        .site-footer__info-content--contact {
            border-top: $border;
        }
    }
    .site-footer__title,
    .site-footer__info-content--contact > h4 {
        &:before {
            content: $icon-plus;
            position: absolute;
            right: 20px;
            font-family: $icomoon-font-family;
            font-size: 10px;
            top: 25px;
            height: unset;
            width: unset;
            transform: unset;
            background: unset;
        }
        &.active:before {
            content: $icon-minus;
        }
    }
    .site-footer__wrapper {
        display: none;
        &.active {
            display: block;
        }
    }
    .site-footer__info-content--contact {
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: $border;
        > h4 {
            position: relative;
        }
        h6, div {
            display: none;
            &.active {
                display: block;
            }
        }
    }
    .site-legal__copyright span {
        padding-bottom: 20px;
    }
    div#onetrust-banner-sdk {
        margin-bottom: 0;
    }
}
